import { createApp } from "vue";
import App from "./App.vue";
import GlobalMixin from "./globalMixin";
import PrimeVue from "primevue/config";
import Dialog from "primevue/dialog";
const app = createApp(App);

import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";

app.use(PrimeVue);

app.component("Dialog-item", Dialog);
app.mixin(GlobalMixin);
app.mount("#app");
